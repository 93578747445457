import { api } from '@app/utils/api'

import { ApiActionBuilder } from '@app/store/apiMiddleware/builder'

import { getStatisticsDescriptor } from './statistics.descriptors'

export const getStatistics = new ApiActionBuilder(getStatisticsDescriptor)
  .setInit((query?: { cache: string }) => ({
    method: 'GET',
    endpoint: api.path('/api/v2/statistics', query),
    headers: api.headers(),
  }))
  .build()
