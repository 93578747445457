import { createSelector } from 'reselect'

import { ApiStatistics } from '@app/constants/ApiTypes/entities'

import moment from '@app/utils/moment'
import { assertApiActionResponse } from '@app/utils/performFetchData'

import { ActionRequiredError } from '@app/packages/ActionRequiredError/ActionRequiredError'
import { intoResult, unwrapResult } from '@app/packages/Result/Result'

import { getStatistics } from '@app/store/actions/api/statistics'
import { createReduxSlice } from '@app/store/redux_slice'
import { routingDateSelector } from '@app/store/selectors/routing'

const statisticsSlice = createReduxSlice<{ loaded_at: string; statistics: ApiStatistics }>('statistics')

export const statisticsResultSelector = createSelector([statisticsSlice.selector, routingDateSelector], (slice, date) =>
  intoResult(() => {
    if (!slice || moment(slice.loaded_at).add(30, 'minutes').isBefore(date)) {
      throw ActionRequiredError.create('Statistics must be fetched', date.format(), async dispatch => {
        const resp = await dispatch(getStatistics({ cache: '1668184206422' })).then(assertApiActionResponse(dispatch, 'Statistics fetch failed'))
        dispatch(statisticsSlice.set({ loaded_at: date.format(), statistics: resp.data }))
      })
    }
    return slice.statistics
  })
)

export const roundedSittersNumResultSelectorSelector = createSelector(statisticsResultSelector, sResult =>
  intoResult(() => {
    const statistics = unwrapResult(sResult)
    return Math.floor(statistics.attributes.active_sitters / 100) * 100
  })
)
