import { Typography } from '@app/components/Typography/Typography'

import classes from './AccordionStyle.module.scss'

export type AccordionStyle = {
  root(): string
  item(): string
  body({ hidden }: { hidden: boolean }): string
  title(): string
  icon({ expanded }: { expanded: boolean }): string
}

export class AccordionStyleFlash implements AccordionStyle {
  root() {
    return ''
  }
  item() {
    return cn(classes.item, classes.is_flash)
  }
  title() {
    return cn(classes.flash_title_text, classes.title, classes.is_flash)
  }
  body({ hidden }: { hidden: boolean }) {
    return cn(classes.flash_body_text, classes.body, classes.is_flash, { [classes.is_hidden]: hidden })
  }
  icon({ expanded }: { expanded: boolean }) {
    return cn(classes.icon, classes.is_flash, { [classes.is_expanded]: expanded })
  }

  static instance = new AccordionStyleFlash()
}

export class AccordionStyleBlocks implements AccordionStyle {
  root() {
    return ''
  }
  item() {
    return cn(classes.item, classes.is_blocks)
  }
  title() {
    return cn(Typography.LandingMainBoldText, classes.title, classes.is_blocks)
  }
  body({ hidden }: { hidden: boolean }) {
    return cn(Typography.MainText, classes.body, classes.is_blocks, { [classes.is_hidden]: hidden })
  }
  icon({ expanded }: { expanded: boolean }) {
    return cn(classes.icon, classes.is_blocks, { [classes.is_expanded]: expanded })
  }

  static instance = new AccordionStyleBlocks()
}
